import Link from 'next/link'
import styled from 'styled-components'

export const GoHomeHocStyled = styled(Link)`
  display: grid;
  place-content: center;

  svg {
    min-width: 36px;
    fill: ${p => p.theme.colors.white};
  }
`

import dynamic from 'next/dynamic'
import type { FC } from 'react'
import { memo } from 'react'
import styled from 'styled-components'

import LogoWithHomeLink from '@components/LogoWithHomeLink'
import ControlButtons from '@components/header/ControlButtons'
import Logout from '@components/header/logout/Logout'
import useIsMobile from '@hooks/mediaQueries/useIsMobile'
import useGetMenuItems from '@hooks/useGetMenuItems'

import { NavSelectorWrapper } from './NavSelectors/styles'
import { LogoutButtonStyled } from './logout/components/LogoutButton/styles'

const NavSelectors = dynamic(() => import('./NavSelectors'))

export const headerId = 'main-header'

const Header: FC = () => {
  const menuData = useGetMenuItems()
  const isMobile = useIsMobile()

  return (
    <HeaderContainer id={headerId}>
      <LogoWithHomeLink />

      {!isMobile && menuData && <NavSelectors menuData={menuData} />}
      <ControlButtons />
      <Logout />
    </HeaderContainer>
  )
}

const HeaderContainer = styled.header`
  width: 100%;
  max-width: ${p => p.theme.sizes.desktopM}px;
  height: 112px;
  padding: 24px 40px;
  margin: 0 auto;
  position: relative;
  z-index: 2;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  ${p => p.theme.mediaQueries.desktopS} {
    height: 56px;
    background: none;
    display: flex;
    justify-content: space-between;
    padding: 24px 24px 0 24px;

    ${NavSelectorWrapper}, ${LogoutButtonStyled} {
      display: none;
    }
  }
`

export default memo(Header)

import type { PopoverProps } from 'antd/es'
import { Popover } from 'antd/es'
import type { FC, ReactNode } from 'react'

import { selectWithLayover } from '@redux/features/search/search.selectors'
import { useAppSelector } from '@redux/hooks'

import {
  searchFormSizes,
  topPartHeightDesktop,
} from '@components/mainPage/mainBlock/searchTrains/search/searchForm/constants/sizes'

import { rootClassName } from '../../constants/classNames'
import { GlobalStyles } from './styles'

type Props = {
  formContent: ReactNode
  isOpened: boolean
  onClose: () => void
  onOpen: () => void
  popoverProps?: PopoverProps
}

const PopupContent: FC<Props> = ({ formContent, isOpened, onClose, onOpen, popoverProps }) => {
  const withLayover = useAppSelector(selectWithLayover)

  return (
    <Popover
      align={{
        overflow: { shiftX: 0, shiftY: 0 },
        targetOffset: [-20, topPartHeightDesktop + (withLayover ? searchFormSizes.layoversHeightDesktop : 0)],
      }}
      arrow={false}
      autoAdjustOverflow={true}
      content={formContent}
      destroyTooltipOnHide={false}
      getPopupContainer={node => node}
      onOpenChange={value => (value ? onOpen() : onClose())}
      open={isOpened}
      overlayInnerStyle={{ background: 'none', boxShadow: 'none', padding: 0 }}
      placement="rightTop"
      rootClassName={rootClassName}
      trigger="click"
      {...(popoverProps || {})}
    >
      <div style={{ inset: 0, position: 'absolute' }}>
        <GlobalStyles />
      </div>
    </Popover>
  )
}

export default PopupContent

import dynamic from 'next/dynamic'
import type { FC } from 'react'
import styled from 'styled-components'

import { GoHomeHocStyled } from '@hocs/links/GoHomeHoc/styles'
import useIsMobile from '@hooks/mediaQueries/useIsMobile'

import TermsAndPolicy from '../termsAndPolicy/TermsAndPolicy'

const LogoWithHomeLink = dynamic(() => import('@components/LogoWithHomeLink'))

const LogoWithTermsAndPolicy: FC = () => {
  const isMobile = useIsMobile()

  return (
    <Container>
      {!isMobile && <LogoWithHomeLink />}
      <TermsAndPolicy />
    </Container>
  )
}

const Container = styled.div`
  display: grid;
  align-items: center;
  grid-template-columns: repeat(2, max-content);
  grid-gap: 40px;
  position: relative;

  ${p => p.theme.mediaQueries.desktop} {
    grid-area: termsAndPolicy;

    ${GoHomeHocStyled} {
      display: none;
    }
  }

  ${p => p.theme.mediaQueries.mobile} {
    grid-template-columns: 100%;
    width: 100%;
  }
`

export default LogoWithTermsAndPolicy

import type { FC } from 'react'
import styled from 'styled-components'

import type { ConfigurationFooterType } from '@Types/common/configurationBlocks'
import Text, { StyledText } from '@typography/Text'
import Title, { Title8 } from '@typography/Title'

const ItemInfo: FC<ConfigurationFooterType> = ({ description, title }) => {
  return (
    <Container>
      <Title level={8}>{title}</Title>
      <Text size="s">{description}</Text>
    </Container>
  )
}

const Container = styled.div`
  ${Title8} {
    display: block;
    margin-bottom: 20px;
    text-transform: uppercase;
  }

  ${StyledText} {
    color: ${p => p.theme.colors.fontSecondary};
  }

  ${p => p.theme.mediaQueries.mobile} {
    ${Title8} {
      margin-bottom: 10px;
    }
  }
`

export default ItemInfo

import { TRIP_MODE } from '@redux/features/search/constants/search.constants'

import type { RootState } from '../../store'

export const selectRoundTrip = (state: RootState) => state.search.tripMode === TRIP_MODE.ROUND_TRIP
export const selectDepartureDate = (state: RootState) => state.search.departureDate
export const selectWithLayover = (state: RootState) => state.search.withLayover
export const selectTripMode = (state: RootState) => state.search.tripMode
export const selectAffiliateProgram = (state: RootState) => state.search.affiliateProgram
export const selectHasTravelerPickerErrors = (state: RootState) => state.search.hasTravelerPickerErrors

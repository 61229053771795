import styled from 'styled-components'

import Button from '@base/buttons/Button'

export const MobileMenuButtonStyled = styled(Button)`
  &.ant-btn {
    ${p => p.theme.mediaQueries.tablet} {
      span {
        display: none;
      }
    }
  }
`

export enum SubscribeEvents {
  CLEAN_SEARCH_MODAL,
  CLEAR_PASSENGER_DATE,
  OPEN_ADVANCED_RESERVATION_MODAL,
  OPEN_BURGER_MENU,
  OPEN_FARE_RULES_MODAL,
  OPEN_MOBILE_VIDEO_PLAYER_MODAL,
  OPEN_SEARCH_MODAL,
  OPEN_TOTAL_PRICE_DISCLAIMER_MODAL,
  OPEN_TRAIN_CARD_GALLERY,
  ORDER_PAGE_INFO_MODAL_OPEN,
  ORDER_PAGE_MODIFY_POSTPONE,
  POSTPONE_MODAL_OPEN,
  SEARCH_HANDLER,
  SEARCH_STATIONS,
  SHOW_SEARCH_FORM,
}

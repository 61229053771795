import styled from 'styled-components'

import { captionSmallStyles } from '@styles/typography/caption'

export const ThemeSwitcherWrapper = styled.div`
  ${captionSmallStyles};
  display: flex;
  align-items: center;
  cursor: pointer;

  svg {
    margin-right: 12px;
    fill: ${p => p.theme.colors.icon};
  }
`

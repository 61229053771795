import type { ValidateErrorEntity } from 'rc-field-form/es/interface'

import type { FieldsHandlersType } from '@Types/search/errorHandler'
import { SearchFormKeys } from '@components/mainPage/mainBlock/searchTrains/search/searchForm/constants/form'
import {
  focusArrivalStation,
  focusDepartureStation,
  openPassengersForm,
  openRangePicker,
  openTravelerPicker,
  selectFirstLayover,
} from '@components/mainPage/mainBlock/searchTrains/search/searchForm/utils/elementsHandlers'

export const fieldsHandlers: FieldsHandlersType = {
  [SearchFormKeys.arrival]: focusArrivalStation,
  [SearchFormKeys.childrenAge]: openPassengersForm,
  [SearchFormKeys.departure]: focusDepartureStation,
  [SearchFormKeys.layover]: selectFirstLayover,
}

export const fieldsHandlersRnModern: FieldsHandlersType = {
  ...fieldsHandlers,
  [SearchFormKeys.childrenAge]: openTravelerPicker,
  [SearchFormKeys.date]: openRangePicker,
}

export const onErrorHandler =
  (fieldsHandlers: FieldsHandlersType) =>
  ({ errorFields }: ValidateErrorEntity) => {
    const firstError = errorFields[0]

    for (const field in fieldsHandlers) {
      if (firstError.name.includes(field)) {
        fieldsHandlers[field as keyof typeof fieldsHandlers]()
        return
      }
    }
  }

import styled from 'styled-components'

import Caption from '@typography/Caption'

export const Wrapper = styled.div`
  display: inline-flex;
  align-items: center;

  svg {
    margin-inline-end: 8px;
    fill: ${p => p.theme.colors.primary};
  }
`

export const XSign = styled(Caption)`
  color: ${p => p.theme.colors.fontSecondary};
`

import type { FC } from 'react'
import { memo } from 'react'
import styled from 'styled-components'

import Button from '@base/buttons/Button'
import { StyledButton } from '@base/buttons/Button/styles'
import { SearchFormKeys } from '@components/mainPage/mainBlock/searchTrains/search/searchForm/constants/form'
import type { StationFieldsProps } from '@components/mainPage/mainBlock/searchTrains/search/searchForm/stationsFields/StationsFields'
import useMediaQuery from '@hooks/mediaQueries/useMediaQuery'
import SwapIcon from '@iconscout/unicons/svg/line/arrows-v-alt.svg'
import { mediaQueries } from '@themes/mediaQueries'
import FormDivider from '@widgets/checkout/timetable/components/SearchForm/formDivider/FormDivider'

const SwitchStations: FC<StationFieldsProps> = ({
  arrivalStationValue,
  departureStationValue,
  horizontal,
  isWidget,
  setFieldValue,
  switchIcon,
}) => {
  const isMobile = useMediaQuery(mediaQueries.mobile)

  const switchHandle = () => {
    setFieldValue(SearchFormKeys.departure, arrivalStationValue)
    setFieldValue(SearchFormKeys.arrival, departureStationValue)
  }
  const isStations = !!(arrivalStationValue && departureStationValue)

  return (
    <SwitchStationsWrapper $horizontal={horizontal}>
      {isWidget && !isMobile && <StyledDivider isWidget={isWidget} />}
      <ButtonStyled
        $horizontal={horizontal}
        $isHidden={!horizontal && !isStations}
        disabled={!isStations}
        iconColor="fontSecondary"
        onClick={switchHandle}
        type="link"
      >
        {switchIcon || <SwapIcon />}
      </ButtonStyled>
    </SwitchStationsWrapper>
  )
}

export const SwitchStationsWrapper = styled.div<{ $horizontal?: boolean }>`
  height: ${p => (p.$horizontal ? '100%' : '0')};
  width: ${p => (p.$horizontal ? 'auto' : '100%')};
  position: relative;
  z-index: 1;
  transition: ${p => p.theme.transitions.default};
  display: grid;
  place-content: center;
`

const StyledDivider = styled(FormDivider)`
  z-index: 2;
  position: absolute;
  left: 50%;
  transform: translate(-50%, 0);
`

export const ButtonStyled = styled(Button)<{ $horizontal?: boolean; $isHidden: boolean }>`
  &.ant-btn {
    z-index: 3;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    position: ${p => (p.$horizontal ? 'relative' : 'absolute')};
    right: ${p => (p.$horizontal ? 0 : 24)}px;
    top: 0;
    transform: ${p => (p.$horizontal ? 'none' : 'translate(0, -50%)')};
    border: 1px solid ${p => p.theme.colors.fontSecondaryLight};
    opacity: ${p => (p.$isHidden ? 0 : 1)};
    visibility: ${p => (p.$isHidden ? 'hidden' : 'visible')};

    &${StyledButton} svg {
      transform: rotate(${p => (p.$horizontal ? '90' : 0)}deg);
    }

    &${StyledButton}, &:not(:disabled):not(.ant-btn-disabled):hover,
    &${StyledButton}:focus {
      background: ${p => p.theme.colors.backgroundMain};
    }
  }
`

export default memo(SwitchStations)

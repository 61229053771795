import { createGlobalStyle } from 'styled-components'

import { appearFromLeft, appearFromTop } from '@styles/animations/popups/appear'
import { disappearToLeft, disappearToTop } from '@styles/animations/popups/disappear'

import { passengersLabelRootClassName, rootClassName } from '../../constants/classNames'

export const GlobalStyles = createGlobalStyle`
  .${passengersLabelRootClassName} {
    position: relative;
  }

  .${rootClassName} {
    width: 100%;
    height: 100%;
    min-width: 320px;
    min-height: 440px;

    .ant-popover-content,
    .ant-popover-inner,
    .ant-popover-inner-content {
        height: inherit;
    }

    ${p => p.theme.mediaQueries.mobile} {
        position: fixed;
        inset: 0 !important;
    }

    ${p => p.theme.mediaQueries.noMobile} {
      &.ant-zoom-big-appear.ant-zoom-big-appear-active,
      &.ant-zoom-big-enter.ant-zoom-big-enter-active {
        ${appearFromTop};
      }

      &.ant-zoom-big-leave.ant-zoom-big-leave-active {
        ${disappearToTop};
      }

      &.ant-zoom-big-appear.ant-popover-placement-rightTop.ant-zoom-big-appear-active,
      &.ant-zoom-big-enter.ant-popover-placement-rightTop.ant-zoom-big-enter-active {
        ${appearFromLeft};
      }

      &.ant-zoom-big-leave.ant-popover-placement-rightTop.ant-zoom-big-leave-active {
        ${disappearToLeft};
      }
    }
  }
`

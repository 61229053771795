import type { IAddress } from '@Types/common/address'
import type { IStation, IStationV3 } from '@Types/stations/station'

export const getStationName = ({ address, ...station }: IStation | IStationV3) =>
  (station as IStation).single_name || (station as IStationV3).name || address.locality || ''

export const getStationAddress = (address: IAddress, country?: string) =>
  `${address?.address_line1 ? address.address_line1 + ' ' : ''}${
    address?.address_line2 ? address.address_line2 + ' ' : ''
  }${address?.locality ? address.locality : ''}${country ? ', ' + country : ''}`

export const getRouteName = ({
  arrival_station,
  departure_station,
}: {
  arrival_station: IStation
  departure_station: IStation
}) => `${getStationName(departure_station)} - ${getStationName(arrival_station)}`

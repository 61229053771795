import type { FC } from 'react'

import type { PassengersCategoryType } from '@Types/common/passengers'

import PassengersTypeIconWithCountItem from './components/PassengersTypeIconWithCountItem'
import { Wrapper } from './styles'

type Props = {
  categories: { category: PassengersCategoryType; count: number }[]
}

const PassengersTypeIconWithCount: FC<Props> = ({ categories }) => {
  return (
    <Wrapper>
      {categories.map(item => (
        <PassengersTypeIconWithCountItem key={item.category} {...item} />
      ))}
    </Wrapper>
  )
}

export default PassengersTypeIconWithCount

import { searchFormSizes } from '@components/mainPage/mainBlock/searchTrains/search/searchForm/constants/sizes'

export const popupPositionsMainPage = (isTablet: boolean, isLayover: boolean) => {
  return {
    bottom: 'auto',
    left: isTablet ? `${searchFormSizes.formWidthTablet}px` : `${searchFormSizes.formWidthDesktop}px`,
    right: 'auto',
    top: `-${(isLayover ? searchFormSizes.layoversHeightDesktop : 0) + searchFormSizes.fieldHeightDesktop * 2}px`,
  }
}

export const popupPositionsTimetablePage = {
  bottom: 'auto',
  left: '0',
  right: 'auto',
  top: '57px',
}

import type { CSSProperties, FC } from 'react'

import { FlagStyled } from './styles'

export type FlagIconProps = {
  code: string
  flagDomain?: string
  height?: CSSProperties['height']
  onClick?: () => void
  styles?: CSSProperties
  width?: CSSProperties['width']
}

const FlagIcon: FC<FlagIconProps> = ({ code, flagDomain, height = '16px', onClick, styles, width = '30px' }) => {
  return (
    <FlagStyled
      $code={code}
      $flagDomain={flagDomain}
      height={height}
      key={code}
      onClick={onClick}
      styles={styles}
      width={width}
    />
  )
}

export default FlagIcon

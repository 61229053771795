import { useEffect, useMemo, useState } from 'react'

import isClient from '@components/common/isClient'

export default (query: string) => {
  const preparedQuery = useMemo(() => query.replace('@media', '').trim(), [query])

  const windowObj = isClient ? window : null

  const [matches, setMatches] = useState(() => {
    const mediaQuery = windowObj && windowObj.matchMedia(preparedQuery)
    return mediaQuery ? mediaQuery.matches : false
  })

  useEffect(() => {
    const matchMedia = window.matchMedia(preparedQuery)
    const handler = () => setMatches(matchMedia.matches)

    matchMedia.addEventListener('change', handler)
    return () => matchMedia.removeEventListener('change', handler)
  }, [preparedQuery])

  return matches
}

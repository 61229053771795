import { useRouter } from 'next/router'

import { paths } from '@constants/routes/routes'

const useIsRoutePage = () => {
  const router = useRouter()
  const isRoutePage = router.pathname.includes(paths.routePage)

  return isRoutePage
}

export default useIsRoutePage

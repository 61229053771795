import styled from 'styled-components'

import { Title6 } from '@typography/Title'

export const WrapperCountInput = styled.div`
  width: 100%;
  padding: 14px 20px 14px 24px;
  border-radius: ${p => p.theme.borderRadius.default};
  border: 1px solid ${p => p.theme.colors.fontSecondaryLight};
  display: flex;
  flex-direction: row;
  align-items: center;

  & > ${Title6} {
    margin-inline-end: auto;
  }

  & > svg {
    margin-inline-end: 16px;
    fill: ${p => p.theme.colors.icon};
  }
`

import type { SelectProps } from 'antd/es'
import { Select } from 'antd/es'
import type { FunctionComponent } from 'react'
import { createGlobalStyle, styled } from 'styled-components'

import { searchFormSizes } from '@components/mainPage/mainBlock/searchTrains/search/searchForm/constants/sizes'
import { captionSmallStyles } from '@styles/typography/caption'
import { title4Styles, title5Styles } from '@styles/typography/title'

export const GlobalStyles = createGlobalStyle`
  .stations-dropdown {
    .ant-select-item-option {
      &:hover {
        background: ${p => p.theme.colors.backgroundAdditionalFormHover};
      }
    }

    .ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
      background-color: ${p => p.theme.colors.backgroundAdditional};

      &:focus {
        background-color: ${p => p.theme.colors.backgroundAdditional};
      }

      &:hover {
        background: ${p => p.theme.colors.backgroundMain};
      }
    }
  }
`

export const DirectionLabel = styled.span`
  opacity: 0;
  transition: 0.3s ease;
  position: absolute;
  left: 24px;
  top: 50%;
  transform: translateY(-130%);
  ${captionSmallStyles};
  color: ${p => p.theme.colors.fontSecondary};

  [dir='rtl'] & {
    right: 24px;
    left: initial;
  }
`

export const StationSelectWrapper = styled.div<{ $withValue: boolean }>`
  position: relative;
  width: 100%;
  height: ${searchFormSizes.fieldHeightDesktop}px;
  display: flex;
  align-items: center;

  &:focus-within ${DirectionLabel}, ${p => p.$withValue && DirectionLabel} {
    opacity: 1;
    z-index: 1;
  }

  ${p => p.theme.mediaQueries.mobile} {
    height: ${searchFormSizes.fieldHeightMobile}px;
  }
`

export const SelectStyled: FunctionComponent<SelectProps<string>> = styled(Select)`
  width: 100%;
  height: 100%;

  &.ant-select-single {
    height: inherit;

    .ant-select-selector {
      height: inherit;
      padding-inline: 64px 0;

      .ant-select-selection-search {
        inset-inline-start: inherit;
        inset-inline-end: inherit;
      }

      .ant-select-selection-search,
      .ant-select-selection-placeholder {
        transition: ${p => p.theme.transitions.default};
        display: flex;
        align-items: center;
      }

      .ant-select-selection-item {
        visibility: visible !important;
        transition: padding ${p => p.theme.transitions.default};
        display: flex;
        align-items: center;
        opacity: 1;
        padding-inline-end: 0;
        max-width: 100%;
      }

      .ant-select-selection-placeholder {
        font-size: 16px;
        transition: padding ${p => p.theme.transitions.default};
        color: ${p => p.theme.colors.fontContrast};
      }

      .ant-select-selection-search-input {
        ${title4Styles};
        position: relative;
        top: 0;
        opacity: 0;
        transition: top ${p => p.theme.transitions.default};
      }
    }
  }

  .ant-select-arrow {
    transition: opacity ${p => p.theme.transitions.default};
    opacity: 1;
    inset-inline-start: 24px;
    inset-inline-end: auto;

    svg {
      fill: ${p => p.theme.colors.iconForm};
    }
  }

  .ant-select-clear {
    height: 24px;
    width: 24px;
    margin-top: -12px;
    opacity: 1;
    inset-inline-end: 24px;
    background: none;

    svg {
      fill: ${p => p.theme.colors.fontSecondary};
    }
  }

  &.withValue .ant-select-selector {
    padding-inline: 24px;
  }

  &.ant-select-focused .ant-select-selector {
    padding-inline: 24px 64px;
  }

  &.ant-select-allow-clear {
    .ant-select-selector {
      padding-inline: 24px 64px;

      .ant-select-selection-search {
        width: calc(100% - 80px);
      }
    }

    &:not(.ant-select-focused):not(.withValue) .ant-select-selector {
      padding-inline: 64px 32px;
    }
  }

  &.ant-select-focused,
  &.withValue {
    .ant-select-arrow {
      opacity: 0;
    }

    .ant-select-selector {
      .ant-select-selection-search-input {
        top: 10px;
      }

      .ant-select-selection-item {
        padding-top: 20px;
      }
    }
  }

  &.withValue {
    .ant-select-selector .ant-select-selection-search-input {
      opacity: 0;
    }
  }

  &.ant-select-focused {
    .ant-select-selector {
      .ant-select-selection-search-input {
        opacity: 1;
      }

      .ant-select-selection-item {
        opacity: 0;
      }

      .ant-select-selection-search {
        z-index: 1;
      }
    }

    .ant-select-selection-placeholder {
      padding-top: 20px;
    }

    ${p => p.theme.mediaQueries.mobileTablet} {
      &.ant-select-single .ant-select-selector .ant-select-selection-search-input {
        ${title5Styles};
      }
    }
  }
`

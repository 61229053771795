import { selectCartData } from '@redux/features/cart/cart.selectors'
import type { RootState } from '@redux/store'

import type { ProductType } from '@Types/analytics/analytics'
import { getRouteName } from '@utils/route/routeName'

export const getProducts = (state: RootState) => {
  const cart = selectCartData(state)
  const products: ProductType[] = cart.map(({ coachClass, fare, passengers, train }) => {
    return {
      category: coachClass.coach_class.name || undefined,
      id: coachClass.coach_class.id,
      name: getRouteName(train),
      price: fare.profit?.number,
      quantity: passengers.adults + passengers.children,
      route_id: `${train.departure_station.uuid} - ${train.arrival_station.uuid}`,
    }
  })

  return products
}

import type { FC } from 'react'

import type { PassengersCategoryType } from '@Types/common/passengers'
import PassengersCategoryIcon from '@components/passengers/passengersCategoryIcon/PassengersCategoryIcon'
import { Text } from '@typography/Text'

import { Wrapper, XSign } from './styles'

type Props = {
  category: PassengersCategoryType
  count: number
}

const PassengersTypeIconWithCountItem: FC<Props> = ({ category, count }) => {
  if (!count) return null

  return (
    <Wrapper>
      <PassengersCategoryIcon category={category} />
      <XSign size="xs">x</XSign>
      <Text>{count}</Text>
    </Wrapper>
  )
}

export default PassengersTypeIconWithCountItem

import { InputNumber } from 'antd/es'
import type { InputNumberProps } from 'antd/es'
import styled from 'styled-components'

import { title6Styles } from '@styles/typography/title'

export const InputNumberStyled = styled((props: InputNumberProps<number>) => <InputNumber {...props} />)`
  width: 116px;

  .ant-input-number-wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .ant-input-number-group-addon {
    width: auto;
    background: none;
    padding: 0;
    border: none;
  }

  .ant-input-number {
    width: auto;
  }

  &.ant-input-number-status-error:not(.ant-input-number-disabled).ant-input-number .ant-input-number-input-wrap {
    box-shadow: inset 0 0 0 1px ${p => p.theme.colors.red};
  }

  .ant-input-number-input-wrap {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 36px;
    height: 36px;
    border-radius: 50%;
    background: ${p => p.theme.colors.backgroundBlueContainer};

    input {
      ${title6Styles};
      caret-color: transparent;
      height: 100%;
      padding: 0;
      color: ${p => p.theme.colors.primary};
      text-align: center;
    }
  }
`

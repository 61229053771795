import type { Dayjs } from 'dayjs'
import dayjs from 'dayjs'

import type { TimetableSearchForm } from '@redux/features/checkout/types/checkoutState'

import { SearchFormKeys } from '@components/mainPage/mainBlock/searchTrains/search/searchForm/constants/form'
import type { SearchFormValuesType } from '@components/mainPage/mainBlock/searchTrains/search/types/formTypes'

export const getInitialValues = (searchForms: TimetableSearchForm[]): SearchFormValuesType => {
  const currentSearchForm = searchForms[0]
  const nextSearchForm = searchForms[1]
  const date =
    nextSearchForm && !nextSearchForm[SearchFormKeys.layover]
      ? [currentSearchForm[SearchFormKeys.date], nextSearchForm[SearchFormKeys.date]].map(date => dayjs(date))
      : [dayjs(currentSearchForm[SearchFormKeys.date]), null]
  return {
    ...currentSearchForm,
    [SearchFormKeys.date]: date as [Dayjs, Dayjs | null],
  }
}

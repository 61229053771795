import type { SelectProps } from 'antd/es'
import { Select } from 'antd/es'
import type { FC } from 'react'
import { useMemo } from 'react'
import styled, { createGlobalStyle } from 'styled-components'

import { maxChildAge } from '@constants/defaultParameters/searchForm'
import { UilAngleDown } from '@iconscout/react-unicons'
import { captionMediumStyles } from '@styles/typography/caption'
import Text from '@typography/Text'

type Props = {
  label: string
  maxAge?: number
} & SelectProps

const AgeSelect: FC<Props> = ({ label, maxAge = maxChildAge, ...props }) => {
  const options = useMemo<SelectProps['options']>(() => {
    return Array.from({ length: maxAge + 1 }).map((_, index) => ({
      label: (
        <Text size="l" style={{ alignItems: 'center', display: 'flex', height: '40px' }} variant="medium">
          {index}
        </Text>
      ),
      value: index,
    }))
  }, [maxAge])

  return (
    <SelectChildWrapper $label={label}>
      <Select
        dropdownAlign={{ offset: [0, -64] }}
        dropdownStyle={{ minWidth: '121px', width: '121px' }}
        options={options}
        placement="bottomRight"
        popupClassName="age-dropdown"
        popupMatchSelectWidth={121}
        suffixIcon={<DropdownIcon />}
        {...props}
      />
      <GlobalStyles />
    </SelectChildWrapper>
  )
}

const GlobalStyles = createGlobalStyle`
  .age-dropdown {
    background: ${p => p.theme.colors.backgroundAdditional};

    .ant-select-item-option {
      &:hover {
        background: ${p => p.theme.colors.backgroundMain};
      }
    }

    .ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
        background-color: ${p => p.theme.colors.backgroundAdditional};
      &:focus {
        background-color: ${p => p.theme.colors.backgroundAdditional};
      }
    }
  }
`

const SelectChildWrapper = styled.div<{ $label: string }>`
  width: 100%;
  height: 64px;

  .ant-select {
    height: inherit;
    width: 100%;

    .ant-select-selection-search-input {
      font-size: 16px;
      height: 60px !important;
    }

    &.ant-select-status-error:not(.ant-select-disabled):not(.ant-select-customize-input):not(
        .ant-pagination-size-changer
      )
      .ant-select-selector {
      border: 1px solid ${p => p.theme.colors.red};
    }

    &:not(.ant-select-disabled):not(.ant-select-customize-input):not(.ant-pagination-size-changer):hover,
    &:not(.ant-select-disabled):not(.ant-select-customize-input):not(.ant-pagination-size-changer) {
      .ant-select-selector {
        background: ${p => p.theme.colors.backgroundMain};
        border: 1px solid ${p => p.theme.colors.fontSecondaryLight};
      }
    }

    &.ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
      height: inherit;
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 0 48px 0 24px;
      border-radius: ${p => p.theme.borderRadius.default};

      &:before {
        ${captionMediumStyles};
        content: '${p => p.$label}';
        display: block;
        color: ${p => p.theme.colors.fontSecondary};
      }

      &:after {
        display: none;
      }

      & > .ant-select-selection-item {
        text-align: center;
        padding-inline-end: 0;
        width: 72px;
        height: 40px;
        flex: initial;
        display: grid;
        place-items: center;
        border-inline-start: 1px solid ${p => p.theme.colors.fontSecondaryLight};

        &:after {
          display: none;
        }
      }
    }

    .ant-select-arrow {
      inset-inline-end: 24px;
    }
  }
`

const DropdownIcon = styled(UilAngleDown)`
  fill: ${p => p.theme.colors.fontSecondary};
`

export default AgeSelect

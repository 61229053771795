import { api } from '@redux/api/api'
import type { LogBodyType } from '@redux/features/analytics/types/log'

import { additionalBaseUrl, urlVersionPrefixV2 } from '@constants/api/api'
import { XAPIUserKeyAdditional } from '@constants/api/tokens'

const analyticsApi = api.injectEndpoints({
  endpoints: build => ({
    putLog: build.mutation<void, LogBodyType>({
      query: ({ id, type }) => ({
        body: { booking_id: id },
        method: 'PUT',
        url: `${urlVersionPrefixV2}log/${type}`,
      }),
    }),
    putLogAdditional: build.mutation<void, LogBodyType>({
      query: ({ id, type }) => ({
        body: { search_session_id: id },
        headers: { 'X-API-User-Key': XAPIUserKeyAdditional },
        method: 'PUT',
        url: `${additionalBaseUrl}${urlVersionPrefixV2}log/${type}`,
      }),
    }),
  }),
})

export const { endpoints: analyticsApiEndpoints } = analyticsApi

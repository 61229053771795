export const disableScroll = () => {
  document.documentElement.style.setProperty('overflow', 'hidden')
  document.documentElement.style.setProperty('-webkit-margin-end', '4px')
  document.body.style.setProperty('overflow', 'hidden')
}

export const enableScroll = () => {
  document.documentElement.style.removeProperty('overflow')
  document.documentElement.style.removeProperty('-webkit-margin-end')
  document.body.style.removeProperty('overflow')
}

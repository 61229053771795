import Link from 'next/link'
import styled from 'styled-components'

import { Title6 } from '@typography/Title'

export const MenuItemWrapper = styled(Link)`
  display: block;
  padding: 14px 36px;

  ${Title6} {
    transition: ${p => p.theme.transitions.default};
    width: fit-content;
    position: relative;
  }
`

import { useRouter } from 'next/router'

import { paths } from '@constants/routes/routes'

const useIsMainPage = () => {
  const router = useRouter()
  const pathname = router.asPath

  return pathname === paths.rootPath || pathname.includes(paths.routePage)
}

export default useIsMainPage

import type { TooltipPlacement } from 'antd/es/tooltip'
import dynamic from 'next/dynamic'
import type { FC } from 'react'

import MobileMenuButton from '@components/header/BurgerMenu/MobileMenuButton/MobileMenuButton'
import { ControlButtonsWrapper } from '@components/header/ControlButtons/styles'
import useIsMobile from '@hooks/mediaQueries/useIsMobile'

const TabletDesktopButtons = dynamic(() => import('./components/TabletDesktopButtons'))

type Props = {
  isMobileMenuButton?: boolean
  placementRegionalMenu?: TooltipPlacement
  placementTicketsMenu?: TooltipPlacement
}

export const ControlButtons: FC<Props> = ({
  isMobileMenuButton = true,
  placementRegionalMenu,
  placementTicketsMenu,
}) => {
  const isMobile = useIsMobile()

  return (
    <ControlButtonsWrapper>
      {!isMobile && (
        <TabletDesktopButtons
          placementRegionalMenu={placementRegionalMenu}
          placementTicketsMenu={placementTicketsMenu}
        />
      )}
      {isMobileMenuButton && <MobileMenuButton />}
    </ControlButtonsWrapper>
  )
}

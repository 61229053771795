import type { LinkProps } from 'next/link'
import type { FC, ReactElement } from 'react'

import { GoHomeHocStyled } from './styles'

export type GoHomeHocProps = {
  children: ReactElement
  to?: LinkProps['href']
} & Omit<LinkProps, 'href'>

export const GoHomeHoc: FC<GoHomeHocProps> = ({ children, to, ...props }) => {
  return (
    <GoHomeHocStyled href={to || '/'} {...props} aria-label="go home link">
      {children}
    </GoHomeHocStyled>
  )
}

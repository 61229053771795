import { useTranslation } from 'next-i18next'
import { type FC, memo } from 'react'

import { passengersLabelClassName } from '@components/mainPage/mainBlock/searchTrains/search/searchForm/constants/classNames'
import Caption from '@typography/Caption'
import Text from '@typography/Text'

import PassengersTypeIconWithCount from './components/PassengersTypeIconWithCount'
import { Children, ChildrenAges, PassengersInfo, PassengersLabelWrapper, UsersIcon } from './styles'

type Props = {
  adultsCount: number
  childrenAge: (number | undefined)[]
  childrenCount: number
  compactLabelView?: boolean
  iconsView?: boolean
}

const PassengersLabel: FC<Props> = ({ adultsCount, childrenAge, childrenCount, compactLabelView, iconsView }) => {
  const { t } = useTranslation(['common', 'Search form'])
  const label = t('passengers', { ns: 'Search form' })
  const withChildren = childrenCount > 0
  const compactView = compactLabelView || withChildren
  const withChildrenAges = withChildren && childrenAge && childrenAge.filter(age => age !== undefined)

  return (
    <PassengersLabelWrapper $compactView={compactView} className={passengersLabelClassName} type="button">
      {!compactView && <UsersIcon />}
      {compactView ? <Caption size="s">{label}</Caption> : <Text size="l">{label}</Text>}
      <PassengersInfo>
        {iconsView ? (
          <PassengersTypeIconWithCount
            categories={[
              { category: 'adult', count: adultsCount },
              { category: 'child', count: childrenCount },
            ]}
          />
        ) : (
          <>
            <span>{`${adultsCount} ${t('adult', { count: adultsCount })}`}</span>
            {withChildren && (
              <Children>
                {`${childrenCount} ${t('child', { count: childrenCount })}`}
                <ChildrenAges>
                  {withChildrenAges && withChildrenAges?.length > 0 && ` (${withChildrenAges.join(', ')})`}
                </ChildrenAges>
              </Children>
            )}
          </>
        )}
      </PassengersInfo>
    </PassengersLabelWrapper>
  )
}

export default memo(PassengersLabel)

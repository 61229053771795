import { api } from '@redux/api/api'
import type { IHeaderConfig, IMenuItem } from '@redux/features/header/types/menu'

import headerUrls from '../constants/urls'

const headerApi = api.injectEndpoints({
  endpoints: build => ({
    getHeaderConfig: build.query<IHeaderConfig, void>({
      query: () => ({ url: headerUrls.config }),
    }),
    getMenuItems: build.query<IMenuItem[], string>({
      query: menuName => ({ url: headerUrls.menuItems(menuName) }),
    }),
  }),
})

export const { endpoints: headerEndpoints, useGetHeaderConfigQuery, useGetMenuItemsQuery } = headerApi

export const focusOnNextField = ({
  fieldsSelector = 'input:not([disabled])',
  formId,
}: {
  fieldsSelector?: string
  formId: string
}) => {
  const form = document.getElementById(formId) as HTMLFormElement | null
  const formFields = form && [...(form.querySelectorAll(fieldsSelector) || [])]
  if (formFields?.length && document.activeElement) {
    const currentFieldIndex = formFields.indexOf(document.activeElement)
    Number.isInteger(currentFieldIndex) && (formFields[currentFieldIndex + 1] as HTMLElement)?.focus()
  }
}

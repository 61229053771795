import set from 'lodash/set'
import type { ParsedUrlQuery } from 'node:querystring'

import { setSearchForms } from '@redux/features/checkout/checkout.slice'
import type { TimetableSearchForm } from '@redux/features/checkout/types/checkoutState'
import { switchTripMode } from '@redux/features/search/search.slice'
import type { SearchStationsOptionType } from '@redux/features/search/types/searchStations'
import { getStation } from '@redux/features/trainData/trainData.api'
import type { RootState } from '@redux/store'
import { createAsyncThunk } from '@reduxjs/toolkit'

import { SearchFormKeys } from '@components/mainPage/mainBlock/searchTrains/search/searchForm/constants/form'
import routes, { getRoutes } from '@constants/routes/routes'

export default createAsyncThunk<
  string | void,
  { query: ParsedUrlQuery; versionPage?: null | string },
  { state: RootState }
>('search/searchParamsInQueryHandler', async ({ query, versionPage }, { dispatch }) => {
  try {
    if (!query['passengers[adults]']) return
    const parsedObject = Object.entries(query).reduce(
      (acc, [path, value]) => {
        set(acc, path, value)
        return acc
      },
      {} as {
        'form-mode': 'basic-mode' | 'roundtrip-mode'
        legs: Array<{
          arrival_station: string
          departure_date: string
          departure_station: string
        }>
        no_redirect: string
        passengers: {
          adults: string
          children?: string
          children_age?: Record<string, string>
        }
      }
    )

    parsedObject.legs = parsedObject.legs.filter(value => !!value)

    const passengersData = {
      [SearchFormKeys.adults]: Number(parsedObject.passengers.adults),
      [SearchFormKeys.children]: Number(parsedObject.passengers.children) || 0,
      [SearchFormKeys.childrenAge]: Object.values(parsedObject.passengers.children_age || []).map(Number),
    }

    if (passengersData[SearchFormKeys.childrenAge].length !== passengersData[SearchFormKeys.children]) {
      throw new Error()
    }

    const stationsIds = Array.from(
      parsedObject.legs.reduce((acc, leg) => acc.add(leg.arrival_station).add(leg.departure_station), new Set<string>())
    )

    const stations = await Promise.all(
      stationsIds.map(stationId => dispatch(getStation.initiate({ stationId })).unwrap())
    ).then(arrayResult =>
      arrayResult.reduce((acc, station, index) => {
        acc[stationsIds[index]] = {
          country_code: station.address.country_code || undefined,
          geolocation: station.geolocation,
          id: stationsIds[index],
          label: station.single_name,
          value: station.uuid,
        }
        return acc
      }, {} as Record<string, SearchStationsOptionType>)
    )

    const result: TimetableSearchForm[] = parsedObject.legs.map(
      ({ arrival_station, departure_date, departure_station }) => ({
        ...passengersData,
        [SearchFormKeys.arrival]: stations[arrival_station],
        [SearchFormKeys.date]: departure_date,
        [SearchFormKeys.departure]: stations[departure_station],
      })
    )

    dispatch(setSearchForms(result))
    parsedObject['form-mode'] === 'roundtrip-mode' && dispatch(switchTripMode())
    return versionPage ? getRoutes.checkoutTimetableVersion(versionPage) : routes.checkoutTimetable
  } catch (e) {
    console.error(e)
  }
})

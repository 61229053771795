import type { StationType } from '@redux/features/routesPage/types/routeInfo'
import type { SearchStationsOptionType } from '@redux/features/search/types/searchStations'

import type { IStation } from '@Types/stations/station'

export default (station: IStation | StationType): SearchStationsOptionType => ({
  country_code: station?.address.country_code || undefined,
  geolocation: station?.geolocation,
  label: station?.single_name || '',
  value: station?.uuid || '',
})

import type { TimetableSearchForm } from '@redux/features/checkout/types/checkoutState'

import { SearchFormKeys } from '@components/mainPage/mainBlock/searchTrains/search/searchForm/constants/form'
import type { SearchFormValuesType } from '@components/mainPage/mainBlock/searchTrains/search/types/formTypes'

export const getSearchForm = (formData: SearchFormValuesType): TimetableSearchForm[] => {
  const dayJsDate = formData[SearchFormKeys.date]

  if (formData[SearchFormKeys.layover] && !dayJsDate[1]) {
    return [
      {
        ...formData,
        [SearchFormKeys.date]: dayJsDate[0].format(),
      },
      {
        ...formData,
        [SearchFormKeys.date]: dayJsDate[0].format(),
      },
    ]
  }

  const firstLeg: TimetableSearchForm = {
    ...formData,
    [SearchFormKeys.date]: dayJsDate[0].format(),
  }

  if (dayJsDate[1]) {
    const secondLeg: TimetableSearchForm = {
      ...formData,
      [SearchFormKeys.arrival]: formData[SearchFormKeys.departure],
      [SearchFormKeys.date]: dayJsDate[1].format(),
      [SearchFormKeys.departure]: formData[SearchFormKeys.arrival],
    }

    return [firstLeg, secondLeg]
  }

  return [firstLeg]
}

import type { PopoverProps } from 'antd/es/popover'
import type { FC } from 'react'
import { memo } from 'react'

import { useAppSelector } from '@redux/hooks'
import { selectIsPageInteraction } from '@reduxCommonApp/commonApp.selectors'

import { passengersLabelRootClassName } from '@components/mainPage/mainBlock/searchTrains/search/searchForm/passengers/constants/classNames'

import PassengersForm from './components/PassengersForm'
import PassengersLabel from './components/PassengersLabel'
import PopupContent from './components/PopupContent'
import { Wrapper } from './styles'

type Props = {
  adultsCount: number
  childrenAge: (number | undefined)[]
  childrenCount: number
  compactLabelView?: boolean
  iconsView?: boolean
  isOpened: boolean
  maxPassengersCount: number
  onClose: () => void
  onOpen: () => void
  popoverProps?: PopoverProps
}

const Passengers: FC<Props> = ({
  adultsCount,
  childrenAge,
  childrenCount,
  compactLabelView,
  iconsView,
  isOpened,
  maxPassengersCount,
  onClose,
  onOpen,
  popoverProps,
}) => {
  const isPageInteraction = useAppSelector(selectIsPageInteraction)

  return (
    <Wrapper className={passengersLabelRootClassName} onClick={() => !isOpened && onOpen()}>
      <PopupContent
        formContent={
          isPageInteraction ? (
            <PassengersForm
              adultsCount={adultsCount}
              childrenAge={childrenAge}
              childrenCount={childrenCount}
              isOpened={isOpened}
              maxPassengersCount={maxPassengersCount}
              onClose={onClose}
            />
          ) : undefined
        }
        isOpened={isOpened}
        onClose={onClose}
        onOpen={onOpen}
        popoverProps={popoverProps}
      />

      <PassengersLabel
        adultsCount={adultsCount}
        childrenAge={childrenAge}
        childrenCount={childrenCount}
        compactLabelView={compactLabelView}
        iconsView={iconsView}
      />
    </Wrapper>
  )
}

export default memo(Passengers)

import { useTranslation } from 'next-i18next'
import type { FC } from 'react'
import { memo } from 'react'
import { useTheme } from 'styled-components'

import Divider from '@base/Divider'
import Text from '@typography/Text'

import Control from './control/Control'
import Info from './info/Info'
import { FooterWrapper, Label } from './styles'

type Props = {
  customerSupportLink?: string
}

const Footer: FC<Props> = ({ customerSupportLink }) => {
  const { t } = useTranslation('Footer')
  const theme = useTheme()

  return (
    <FooterWrapper>
      <Control customerSupportLink={customerSupportLink} />
      <Divider style={{ borderColor: theme.colors.border, margin: '0' }} type="horizontal" />
      <Info />
      <Label>
        <Text size="s">{t('allRightsReserved', { year: new Date().getFullYear() })}</Text>
      </Label>
    </FooterWrapper>
  )
}

export default memo(Footer)

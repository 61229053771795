import styled from 'styled-components'

import { ThemeSwitcherWrapper } from '@components/ThemeSwitcher/styles'
import { MobileMenuButtonStyled } from '@components/header/BurgerMenu/MobileMenuButton/styles'
import { RegionalMenuContainer } from '@components/header/RegionalMenu/styles'
import { MyTripsPopupHOCContainer } from '@components/myTrips/MyTripsPopup/styles'

export const ControlButtonsWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 40px;

  ${MobileMenuButtonStyled} {
    display: none;
  }

  ${p => p.theme.mediaQueries.desktopS} {
    ${MobileMenuButtonStyled}, ${MyTripsPopupHOCContainer} {
      display: flex;
    }
  }

  ${p => p.theme.mediaQueries.mobile} {
    ${MyTripsPopupHOCContainer}, ${RegionalMenuContainer}, ${ThemeSwitcherWrapper} {
      display: none;
    }
  }
`

export enum AnalyticsEvents {
  ADD_TO_CART = 'addToCart',
  CHECKOUT = 'checkout',
  DETAILS = 'detail',
  FARE_VIEW = 'fare_view',
  PASSENGER_PAGE = 'passenger_page',
  PAY_BTN_CLICK = 'pay_btn_click',
  PURCHASE = 'purchase',
  TIMETABLE_FAILED = 'timetable_failed',
  TIMETABLE_SUCCESS = 'timetable_success',
  TRAIN_SELECT = 'train_select',
}

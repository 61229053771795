import styled from 'styled-components'

import { TIMETABLE_FORM_HEIGHT } from '@rnConstants/sizes/timetableSizes'
import { textLargeMediumStyles } from '@styles/typography/text'
import { Title6 } from '@typography/Title'

export const LayoverContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 209px;
  height: ${TIMETABLE_FORM_HEIGHT}px;
`

export const Wrapper = styled.div<{ $isMainPage: boolean }>`
  height: 52px;
  min-width: 169px;
  padding-inline: ${p => (p.$isMainPage ? 20 : 16)}px;
  display: flex;
  align-items: center;
  justify-content: ${p => (p.$isMainPage ? 'center' : 'space-between')};
  border-radius: ${p => p.theme.borderRadius.default};
  border: 1px dashed ${p => p.theme.colors.primary};
  cursor: pointer;

  ${Title6} {
    margin: 0 12px;
  }

  ${p => p.theme.mediaQueries.mobileTablet} {
    height: 48px;
  }
`

export const Label = styled.span`
  ${textLargeMediumStyles};
  color: ${p => p.theme.colors.greyCarbon};
`

export const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;

  ${Title6} {
    margin: 0;
  }
`

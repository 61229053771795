import { pushAnalyticEvent } from '@redux/features/analytics/thunks'
import { cleanCart } from '@redux/features/cart/cart.slice'
import { cleanBooking, setSearchForms } from '@redux/features/checkout/checkout.slice'
import { getSearchForm } from '@redux/features/checkout/utils/searchForms'
import { cleanUpFilters } from '@redux/features/trainFilters/trainFilters.slice'
import type { RootState } from '@redux/store'
import { createAsyncThunk } from '@reduxjs/toolkit'

import type { SearchFormValuesType } from '@components/mainPage/mainBlock/searchTrains/search/types/formTypes'
import { AnalyticsEvents } from '@constants/analytics/analyticsEvents'

export default createAsyncThunk<void, SearchFormValuesType, { state: RootState }>(
  'search/onSearch',
  (values, { dispatch }) => {
    dispatch(cleanUpFilters())
    dispatch(setSearchForms(getSearchForm(values)))
    dispatch(cleanCart())
    dispatch(cleanBooking())
    void dispatch(pushAnalyticEvent({ event: AnalyticsEvents.DETAILS }))
  }
)

import type { FC } from 'react'
import { memo } from 'react'

import type { PassengersCategoryType } from '@Types/common/passengers'
import type { Icon } from '@iconscout/react-unicons'
import ChildIcon from '@iconscout/unicons/svg/line/kid.svg'
import AdultIcon from '@iconscout/unicons/svg/line/user.svg'

type Props = {
  category?: PassengersCategoryType
}

const categoryIcons: Record<PassengersCategoryType, Icon> = {
  adult: AdultIcon,
  child: ChildIcon,
  infant: ChildIcon,
}

const PassengersCategoryIcon: FC<Props> = ({ category = 'adult' }) => {
  const Icon = categoryIcons[category]
  return <Icon />
}

export default memo(PassengersCategoryIcon)

import { css } from 'styled-components'

export const fieldsStyles = css`
  .ant-form-item {
    margin-bottom: 0;
  }

  .ant-form-item-explain-error {
    display: none;
  }
`
